@import-normalize;

@import "libs/vars";
@import "libs/functions";
@import "libs/mixins";
@import "libs/skel";

// NOTE: This import was moved into index.html template
// @import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:400,700|Raleway:400,800,900');

/*
	Future Imperfect by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

@include skel-breakpoints(
  (
    xlarge: "(max-width: 1680px)",
    large: "(max-width: 1280px)",
    medium: "(max-width: 980px)",
    small: "(max-width: 736px)",
    xsmall: "(max-width: 480px)",
  )
);

@include skel-layout(
  (
    reset: "full",
    boxModel: "border",
    grid: (
      gutters: 1em,
    ),
  )
);

#root {
  height: 100%;
}

// Base.

@import "base/page";
@import "base/typography";

// Component.

@import "components/author";
@import "components/blurb";
@import "components/box";
@import "components/button";
@import "components/form";
@import "components/icon";
@import "components/image";
@import "components/list";
@import "components/mini-post";
@import "components/post";
@import "components/section";
@import "components/table";
@import "components/hamburger";
@import "components/markdown";

// Layout.

@import "layout/header";
@import "layout/wrapper";
@import "layout/main";
@import "layout/sidebar";
@import "layout/intro";
@import "layout/footer";
@import "layout/menu";

@import "pages/notFound";
@import "pages/landing";
